.i{
    display: flex;
    height: 100vh;
}

.i-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-right{
    flex: 1;
    position: relative;
}

.i-left-wrapper{
    padding: 50px;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}

.i-intro{
    font: 30px;
    font-weight: 300;
}

.i-name{
    font-size: 60px;
}

.i-title{
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper {
   height: 100%; 
   animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    20% {
        transform: translateY(-40px); 
    }
    40% {
        transform: translateY(-80px); 
    } 
    60% {
        transform: translateY(-120px); 
    } 
    80% {
        transform: translateY(-1600px);
     } 
     100% {
        transform: translateY(-200px);
     }
}

.i-title-item {
   height: 40px;
   font-size: 30px;
   font-weight: bold; 
   color: #59b256;
   display: flex;
   align-items: center;
}

.i-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.i-bg{
    clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: #59b256;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}


@media screen  and (max-width: 480px){
    .i{
        flex-direction: column;

    }

    .i-left-wrapper{
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 80%;
    
    }
    .i-desc{
        display: none;
    }
}